.progress {
  height: 35px !important;
  margin-bottom: 10px;
}

.progress .skill {
  font-family: "Open Sans", sans-serif;
  line-height: 35px;
  padding: 0;
  margin: 0 0 0 20px;
  text-transform: uppercase;
}

.progress .skill .val {
  float: right;
  font-style: normal;
  margin: 0 20px 0 0;
}

.progress-bar {
  width: 1px;
  text-align: left;
  transition: .9s;
}