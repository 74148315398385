.left-item-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;
}

.title-element {
	position: relative;
	left: calc(2.5rem / 2);
	padding: 0.6rem;
}

@media (max-width: 600px) {
	.title-element {
		display: none;
	}
}

.icon-wrapper {
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
	padding: 0.15rem;
	position: relative;
	right: calc(-2.5rem / 2);
	top: calc(2.5rem / 2);
	display: inline-block;
}

.right-item-wrapper {
	padding: 1rem;
}

@media (max-width: 960px) {
	.right-item-wrapper {
		padding: 1rem 0;
	}
}

.shadow {
	filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.top-element,
.lower-element {
	height: 1.25rem;
}

.bordered {
	border-right: 4px solid grey;
}