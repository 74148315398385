.section-header-title {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-header-title--light {
  color: #111;
}

.section-header-title--dark {
  color: #fff;
}

.section-header-title::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-header-title::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #3f51b5;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-header-details {
  text-align: center;
  padding-bottom: 30px;
}

.section-header-details--light {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
}

.section-header-details--dark {
  text-align: center;
  padding-bottom: 30px;
  color: #fff;
}
