.cards-page-container {
	padding-top: 0.5rem;
}

.cards-page-container .cards-page-content {
	justify-content: center;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	align-items: stretch;
	gap: 1rem;
}

.cards-page-container .cards-page--centered {
	text-align: center;
}