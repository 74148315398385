.admin-app {
	display: flex;
	gap: 8px;
}

.page-wrapper {
	width: calc(100% - 8px);
}

@media screen and (max-width: 959.95px) {
	.drawer-wrapper {
		width: 100%;
	}

	.admin-app {
		flex-direction: column;
	}
}

@media screen and (min-width: 959.95px) {
	.drawer-wrapper {
		max-width: 280px;
	}

	.admin-app {
		flex-direction: row;
	}
}