@media (max-width: 960px) {
	.section-desktop {
		display: none;
	}

	.section-mobile {
		display: flex;
		flex-direction: column;
	}
}

.section-desktop {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1300;
}

.section-mobile {
	display: none;
}

.navbar {
	background-color: #3f51b5;
	color: white;
	padding: 8px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	position: static;
}

.vertical {
	background-color: #3f51b5;
	color: white;
	padding: 8px;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 20px;
	font-weight: bold;
	margin-left: 16px;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
}

.navigator {
	display: flex;
	align-content: center;
	gap: 16px;
}