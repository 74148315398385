.about-col {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.about-col .img {
  position: relative;
}

.about-col .img img {
  border-radius: 4px 4px 0 0;
}

.about-col .icon {
  width: 64px;
  height: 64px;
  padding-top: 8px;
  text-align: center;
  position: absolute;
  background-color: #3f51b5;
  border-radius: 50%;
  text-align: center;
  border: 4px solid #fff;
  left: calc( 50% - 32px);
  bottom: -30px;
  transition: 0.3s;
}

.about-col i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}

.about-col:hover .icon {
	transition: 0.5s;
  background-color: #fff;
}

.about-col:hover i {
  color: #3f51b5;
}

.about-col h2 {
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 40px 0 12px 0;
}

.about-col h2 a {
  color: #000;
}

.about-col h2 a:hover {
  color: #3f51b5;
}

.about-col h2 a:hover {
  color: #3f51b5;
}

.about-col p {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 0;
  padding: 0 20px 20px 20px;
}

.title a {
	transition: 0.5s;
	text-decoration: none;
}

.title:hover a {
	text-decoration: none;
}
