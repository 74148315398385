.list-item {
	cursor: pointer;
}

.item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
}