.margin--quarter-rem {
	margin: 0.25rem;
}

.margin--half-rem {
	margin: 0.5rem;
}

.margin--one-rem {
	margin: 1rem;
}

.margin--two-rem {
	margin: 2rem;
}

.margin-left--half-rem {
	margin-right: 0.5rem;
}

.margin-right--half-rem {
	margin-right: 0.5rem;
}

.margin-top--half-rem {
	margin-top: 0.5rem;
}

.margin-top--one-rem {
	margin-top: 1rem;
}

.margin-bottom--half-rem {
	margin-top: 0.5rem;
}

.padding--half-rem {
	padding: 0.5rem;
}

.padding-one-rem {
	padding: 1rem;
}

.padding-top--one-rem {
	padding-top: 1rem;
}

.padding-bottom--half-rem {
	padding-top: 0.5rem;
}

.display-flex {
	display: flex;
}

.display-flex--column {
	display: flex;
	flex-direction: column;
}

.display-flex--row {
	display: flex;
	flex-direction: row;
}

.align-items--center {
	align-items: center;
}

.width--hundred-percent {
	width: 100%;
}

.text-align--center {
	text-align: center;
}

.align-self--stretch {
	align-self: stretch;
}

.gap--half-rem {
	gap: 0.5rem;
}

.cursor--pointer {
	cursor: pointer;
}

.justify-content--center {
	justify-content: center;
}