.button {
	min-width: unset;
}

svg.expand {
	transform: rotate(0deg);
	margin-left: auto;
	cursor: pointer;
	transition: transform 200ms;
}

svg.expand-open {
	transform: rotate(180deg);
	margin-left: auto;
}