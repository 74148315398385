.wrapper {
	text-align: center;
	display: grid;
	grid-template-columns: 25% 75%;
	align-items: stretch;
}

@media (max-width: 960px) {
	.wrapper {
		grid-template-columns: 5% minmax(min-content, 1fr);
	}
}