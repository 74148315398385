#admin-drawer .section {
	cursor: pointer;
}

#admin-drawer .section:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

#admin-drawer .section-dark:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

#admin-drawer .section-item {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 16px;
}

#admin-drawer .section-item:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

#admin-drawer .section-item-dark:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

div.admin-drawer {
	height: 100%;
	min-height: 100vh;
	z-index: 1200;

	@media (max-width: 960px) {
		width: 100%;
		height: unset;
	}
}

div#admin-drawer .title {
	font-size: 1.5rem;
	line-height: 1.334;
	cursor: pointer;
	padding: 8px;
}

div#admin-drawer .title:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

div#admin-drawer div.logout-button-wrapper {
	display: flex;
	padding: 8px;
	justify-content: center;
}