.form-page-wrapper {
	margin-top: 8px;
}

.form-page-wrapper .form-page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.form-page-wrapper .form-page-container .form-page-button-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}