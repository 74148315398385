.mobile-row-paper {
	padding: 8px;
}

.mobile-row-paper-selected {
	border: 2px solid #f50057;
}

.mobile-row-grid-parent {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 8px;
	column-gap: 8px;
}

.mobile-row-grid-parent .mobile-row-grid-item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}